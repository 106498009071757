import Swal from 'sweetalert2';

const Toast = {
  mixin: Swal.mixin({
    toast: true,
    animation: true,
    position: 'top-right',
    showConfirmButton: false,
    showCloseButton: true,
    timer: 6000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  }),

  /**
   * Exibe uma mensagem de sucesso
   * @param {string} title - O título da mensagem
   * @param {Array<string>} [messages] - Lista opcional de mensagens detalhadas
   */
  Ok(title, ...messages) {
    this._showToast('success', title, messages);
  },

  /**
   * Exibe uma mensagem de erro
   * @param {string} title - O título da mensagem
   * @param {Array<string>} [messages] - Lista opcional de mensagens detalhadas
   */
  Error(title, ...messages) {
    this._showToast('error', title, messages);
  },

  /**
   * Exibe uma mensagem de alerta
   * @param {string} title - O título da mensagem
   * @param {Array<string>} [messages] - Lista opcional de mensagens detalhadas
   */
  Alert(title, ...messages) {
    this._showToast('warning', title, messages);
  },

  /**
   * Exibe uma mensagem de informação
   * @param {string} title - O título da mensagem
   * @param {Array<string>} [messages] - Lista opcional de mensagens detalhadas
   */
  Info(title, ...messages) {
    this._showToast('info', title, messages);
  },

  /**
   * Função interna para exibir um toast
   * @param {string} icon - Tipo de ícone (success, error, warning, info)
   * @param {string} title - O título da mensagem
   * @param {Array<string>} messages - Lista de mensagens detalhadas
   */
  _showToast(icon, title, messages) {
    const html = messages.length > 0
      ? `<ul>${messages.map(msg => `<li>${msg}</li>`).join('')}</ul>`
      : '';

    this.mixin.fire({
      icon: icon,
      title: title,
      html: html
    });
  }
};

export { Toast };